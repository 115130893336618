// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI styles
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";
@import "@coreui/coreui/scss/mixins/_ltr-rtl.scss";

@import "~bootstrap/dist/css/bootstrap.min.css";

@import "rc-tree/assets/index.css";
// Import Bootstrap styles
// @import "~bootstrap/scss/bootstrap";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";
