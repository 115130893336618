// Here you can add other styles
.proc-button {
  margin: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: max-content;
}

.spread {
  display: flex;
  justify-content: space-between;
}

.place-end {
  display: flex;
  flex-direction: row-reverse;
}

.pointer {
  cursor: pointer;
}

.tour-btn {
  margin-top: -95px;
  z-index: 100000000;
}

.rc-tree-title {
  width: 100%;
}

.rc-tree-node-content-wrapper {
  width: 100%;
}

.overflow-visible{
  z-index: 1 !important;
}

.ag-theme-alpine {
  --ag-font-size: 15px;
}

.loading-overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  display: block;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.btn-fix button {
  margin-top: -30px !important;
}