button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}

.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}